var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_vm._t("header"),_c('b-spinner',{staticClass:"mx-auto mt-3 d-block",attrs:{"type":"grow","small":""}})],2):(_vm.items.length > 0)?_c('div',[_vm._t("header"),_c('div',{ref:"container",staticClass:"x-scroll-container",class:[_vm.scrollContainerClass, { 'not-scrollable': !_vm.isScrollable }],attrs:{"id":_vm.containerId}},[(_vm.searchType)?_c('div',{staticClass:"h-100"},[(_vm.isDraggable)?_c('draggable',{attrs:{"list":_vm.items,"move":_vm.checkMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_c('transition-group',_vm._l((_vm.items),function(item,indx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              (item.type === null &&
                _vm.searchType.name === _vm.$t('display-type-options.show-all')) ||
                (item.type !== null && item.type.name.en === _vm.searchType.name) ||
                _vm.searchType.name === _vm.$t('display-type-options.show-all')
            ),expression:"\n              (item.type === null &&\n                searchType.name === $t('display-type-options.show-all')) ||\n                (item.type !== null && item.type.name.en === searchType.name) ||\n                searchType.name === $t('display-type-options.show-all')\n            "}],key:indx,ref:_vm.getItemId(indx),refInFor:true,staticClass:"x-scroll-element mx-1 mb-1 item-container",class:[
              { 'initial-element': indx === _vm.initialIndex } ].concat( _vm.computedItemClass ),attrs:{"id":_vm.getItemId(indx)}},[_vm._t("default",null,{"item":item,"index":indx})],2)}),0)],1):_vm._l((_vm.items),function(item,indx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
          (item.type === null &&
            _vm.searchType.name === _vm.$t('display-type-options.show-all')) ||
            (item.type !== null && item.type.name.en === _vm.searchType.name) ||
            _vm.searchType.name === _vm.$t('display-type-options.show-all')
        ),expression:"\n          (item.type === null &&\n            searchType.name === $t('display-type-options.show-all')) ||\n            (item.type !== null && item.type.name.en === searchType.name) ||\n            searchType.name === $t('display-type-options.show-all')\n        "}],key:indx,ref:_vm.getItemId(indx),refInFor:true,staticClass:"x-scroll-element mx-1 mb-1 item-container",class:[
          { 'initial-element': indx === _vm.initialIndex } ].concat( _vm.computedItemClass ),attrs:{"id":_vm.getItemId(indx)}},[_vm._t("default",null,{"item":item,"index":indx})],2)})],2):_c('div',{staticClass:"h-100"},[(_vm.isDraggable)?_c('draggable',{staticClass:" zindex-3",attrs:{"ghost-class":"ghost","list":_vm.items,"move":_vm.checkMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_c('transition-group',_vm._l((_vm.items),function(item,indx){return _c('div',{key:indx,ref:_vm.getItemId(indx),refInFor:true,staticClass:"x-scroll-element mx-1 mb-1 item-container zindex-3",class:[
              { 'initial-element': indx === _vm.initialIndex } ].concat( _vm.computedItemClass ),attrs:{"id":_vm.getItemId(indx)}},[_vm._t("default",null,{"item":item,"index":indx})],2)}),0)],1):_vm._l((_vm.items),function(item,indx){return _c('div',{key:indx,ref:_vm.getItemId(indx),refInFor:true,staticClass:"x-scroll-element mx-1 mb-1 item-container",class:[
          { 'initial-element': indx === _vm.initialIndex } ].concat( _vm.computedItemClass ),attrs:{"id":_vm.getItemId(indx)}},[_vm._t("default",null,{"item":item,"index":indx})],2)})],2),(_vm.showArrowPrevious)?_c('button',{staticClass:"link-previous btn btn-icon btn-primary btn-previous",class:{ 'btn-previous--loading': _vm.loadingPrevious },on:{"click":function($event){return _vm.scrollOnePage(-1)}}},[_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}})],1):_vm._e(),(_vm.showArrowNext)?_c('button',{staticClass:"link-next btn btn-icon btn-primary btn-next",class:{ 'btn-next--loading': _vm.loadingNext },on:{"click":function($event){return _vm.scrollOnePage(+1)}}},[_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}})],1):_vm._e(),_vm._t("footer",null,{"items":_vm.items})],2)],2):(_vm.placeholder || _vm.placeholderMessage)?_c('b-row',{staticClass:"horizontal-placeholder"},[_vm._t("header"),(_vm.placeholder)?_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.placeholder}})]):_vm._e(),(_vm.placeholderMessage)?_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.placeholderMessage)+" ")])]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }