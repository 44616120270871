<template>
  <div v-if="loading">
    <slot name="header" />
    <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
  </div>
  <div v-else-if="items.length > 0">
    <slot name="header" />
    <div
      :id="containerId"
      ref="container"
      class="x-scroll-container"
      :class="[scrollContainerClass, { 'not-scrollable': !isScrollable }]"
    >
      <div v-if="searchType" class="h-100">
        <draggable
          v-if="isDraggable"
          v-model="items"
          :list="items"
          :move="checkMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group>
            <div
              v-for="(item, indx) in items"
              v-show="
                (item.type === null &&
                  searchType.name === $t('display-type-options.show-all')) ||
                  (item.type !== null && item.type.name.en === searchType.name) ||
                  searchType.name === $t('display-type-options.show-all')
              "
              :id="getItemId(indx)"
              :ref="getItemId(indx)"
              :key="indx"
              class="x-scroll-element mx-1 mb-1 item-container"
              :class="[
                { 'initial-element': indx === initialIndex },
                ...computedItemClass,
              ]"
            >
              <slot :item="item" :index="indx" />
            </div>
          </transition-group>
        </draggable>
        <div
          v-for="(item, indx) in items"
          v-else
          v-show="
            (item.type === null &&
              searchType.name === $t('display-type-options.show-all')) ||
              (item.type !== null && item.type.name.en === searchType.name) ||
              searchType.name === $t('display-type-options.show-all')
          "
          :id="getItemId(indx)"
          :ref="getItemId(indx)"
          :key="indx"
          class="x-scroll-element mx-1 mb-1 item-container"
          :class="[
            { 'initial-element': indx === initialIndex },
            ...computedItemClass,
          ]"
        >
          <slot :item="item" :index="indx" />
        </div>
      </div>
      <div v-else class="h-100">
        <draggable
          v-if="isDraggable"
          v-model="items"
          ghost-class="ghost"
          :list="items"
          :move="checkMove"
          class=" zindex-3"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group>
            <div
              v-for="(item, indx) in items"
              :id="getItemId(indx)"
              :ref="getItemId(indx)"
              :key="indx"
              class="x-scroll-element mx-1 mb-1 item-container zindex-3"
              :class="[
                { 'initial-element': indx === initialIndex },
                ...computedItemClass,
              ]"
            >
              <slot :item="item" :index="indx" />
            </div>
          </transition-group>
        </draggable>
        <div
          v-for="(item, indx) in items"
          v-else
          :id="getItemId(indx)"
          :ref="getItemId(indx)"
          :key="indx"
          class="x-scroll-element mx-1 mb-1 item-container"
          :class="[
            { 'initial-element': indx === initialIndex },
            ...computedItemClass,
          ]"
        >
          <slot :item="item" :index="indx" />
        </div>
      </div>

      <button
        v-if="showArrowPrevious"
        class="link-previous btn btn-icon btn-primary btn-previous"
        :class="{ 'btn-previous--loading': loadingPrevious }"
        @click="scrollOnePage(-1)"
      >
        <b-spinner small class="spinner" />
      </button>

      <button
        v-if="showArrowNext"
        class="link-next btn btn-icon btn-primary btn-next"
        :class="{ 'btn-next--loading': loadingNext }"
        @click="scrollOnePage(+1)"
      >
        <b-spinner small class="spinner" />
      </button>

      <slot name="footer" :items="items" />
    </div>
  </div>
  <!-- Placeholder -->
  <b-row
    v-else-if="placeholder || placeholderMessage"
    class="horizontal-placeholder"
  >
    <slot name="header" />
    <b-col v-if="placeholder" cols="12">
      <img :src="placeholder">
    </b-col>
    <b-col v-if="placeholderMessage" cols="12">
      <p class="text-primary">
        {{ placeholderMessage }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import draggable from 'vuedraggable';

export default {
  name: 'BaseHorizontalContainer',
  components: { draggable },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemClass: {
      type: [String, Object, Array],
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    itemType: {
      type: String,
      default: '',
    },
    placeholderMessage: {
      type: String,
      default: '',
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: Object,
    },
    /**
     * The item with this index is scrolled to the first position.
     */
    initialIndex: {
      type: Number,
      default: 0,
    },
    scrollContainerClass: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 1000,
    },
    /**
     * Used to force the component to show the scroll arrows.
     * Note: This was added because PeopleGlobal and EntitiesGlobal are buggy.
     * Once fixed (fetch all the types first) we might be able to remove this prop.
     */
    scrollable: Boolean,
    loading: Boolean,
    loadingPrevious: Boolean,
    loadingNext: Boolean,
    actualPage: Number,
  },
  data() {
    return {
      containerId: null,
      isGoingToInitialPosition: false,
      isScrollable: true,
      lastLoadedPage: 1,
      isSending: false,
      dragging: false,
      actualItems: [],
    };
  },
  computed: {
    showArrowNext() {
      return (
        !this.loading && !this.isLoadingNext
        && (this.isScrollable || this.scrollable)
      );
    },
    showArrowPrevious() {
      return (
        !this.loading && !this.isLoadingPrevious
        && (this.isScrollable || this.scrollable)
      );
    },
    computedItemClass() {
      return typeof this.itemClass instanceof Array
        ? this.itemClass
        : [this.itemClass];
    },
  },
  watch: {
    async initialIndex(index) {
      this.isGoingToInitialPosition = true;
      const itemId = this.getItemId(index);
      if (!this.$refs[itemId]) {
        return;
      }
      try {
        this.$scrollTo(this.$refs[itemId][0], 0, {
          container: `#${this.containerId}`,
          x: true,
          y: false,
          onDone: () => {
            this.isGoingToInitialPosition = false;
          },
        });
      } catch {}
    },
    async loading(loading) {
      if (loading) return;

      await this.$nextTick();
      this.handleResize();
    },
  },
  created() {
    this.containerId = `container-${uuidv4()}`;
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    /**
     * Scroll one page to the direction given by 'direction'.
     * @param direction {+1|-1} If +1 the it will scroll, otherwise it will scroll to the left
     */
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      try {
        this.$scrollTo(this.$refs.container, 500, {
          container: this.$refs.container,
          duration: 500,
          offset: currentScroll + direction * toScroll,
          x: true,
          y: false,
          onDone: (srcElement) => {
            this.onScroll({ srcElement });
          },
        });
      } catch {}
    },
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.items];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [indx, item] of this.actualItems.entries()) {
        item.order = indx + 1;
      }
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: `/${this.itemType}/order`,
          requestConfig: {
            [this.itemType]: this.actualItems,
          },
        },
      });
      this.$emit('changeOrder', this.actualItems);
      this.isSending = false;
    },
    async onScroll(event) {
      if (this.isGoingToInitialPosition) return;

      const distanceToEnd = event.srcElement.scrollWidth
        - event.srcElement.scrollLeft
        - event.srcElement.offsetWidth;
      if (distanceToEnd <= 0 && !this.loadingNext) {
        this.$emit('load-next');
      }

      if (event.srcElement.scrollLeft === 0 && !this.loadingPrevious) {
        this.$emit('load-previous');
      }
    },
    handleResize() {
      if (!this.$refs.container) return;

      const { scrollWidth, offsetWidth } = this.$refs.container;

      this.isScrollable = scrollWidth !== offsetWidth;
      this.$emit('update:scrollable', this.isScrollable);
    },
    getItemId(index) {
      return `${this.containerId}--${index}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.x-scroll-container {
  height: 100%;
  overflow-y: hidden;
}
.item-container {
  vertical-align: top;
}

.landing-layout {
  .not-scrollable {
    justify-content: center;
    display: flex;
  }
}
</style>
